import { Notyf } from 'notyf';

const notyf = new Notyf({
    duration: 3000,
    position: {
        x: 'right',
        y: 'top',
    },
});

const Notification = (message, type, options) => {
    notyf.open({
        type: type,
        message: message,
        ...options,
    })

    return ""
}

export default Notification;
