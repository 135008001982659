import axios from "axios";

try {
    if (localStorage.getItem("credits") === null || parseInt(localStorage.getItem("credits")) > 1) {
        localStorage.setItem("credits", 1);
    };
} catch (ex) {
    console.log(ex);
    localStorage.setItem("credits", 1);
}
// import logger from "./logService";
// import { toast } from "react-toastify";

// axios.interceptors.response.use(null, error => {
//   const expectedError =
//     error.response &&
//     error.response.status >= 400 &&
//     error.response.status < 500;

//   if (!expectedError) {
//     logger.log(error);
//     toast.error("An unexpected error occurrred.");
//   }

//   return Promise.reject(error);
// });

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
function setJwt(jwt) {
    axios.defaults.headers.common["x-auth-token"] = jwt;
}

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    setJwt,
};