import React from "react";

const TextInput = ({name, label, onChange, placeholder, required, value, type}) => {
  return (
    <div className="mb-2">
      <label className="block text-sm font-medium mb-1" htmlFor={name}>
        {label}
      </label>
      <input
        onChange={onChange}
        id={name}
        className="form-input w-full px-4 py-3 text-gray-900 disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-900 disabled:cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-indigo-600"
        type={type? type: "text"}
        name={name}
        required={required}
        placeholder={placeholder}
        defaultValue={value}
        disabled={value && name==="id" ? true: false}
      />
    </div>
  );
};

export default TextInput;
