import http from "./httpService";
import { apiUrl } from "../config.json";

const apiEndpoint = apiUrl+"/people-search-api/find-people/";


/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export async function searchPeople(data) {
    const response = await http.post(`${apiEndpoint}`,
        {...data});
    return response;
}

export default {
    searchPeople
};
