import React, { Component } from "react";
import TextInput from "./text";
import { ChatIcon } from "@heroicons/react/outline";
import { BadgeCheckIcon } from "@heroicons/react/solid";
import ModalBasic from "../components/ModalBasic";
import Notification from "../components/notifications/notfy";

// Services
import { searchPeople } from "../services/search";
import { CircularProgress } from "@material-ui/core";

class Search extends Component {
  state = {
    data: {},
    credits: localStorage.getItem("credits"),
    modalOpen: false,
    peopledata: {
      email: "mayank@surgestreet.com",
      first_name: "Mayank",
      headline: "Co-Founder",
      last_name: "Chaba",
      linkedin_url: "http://www.linkedin.com/in/mayankchaba",
      phone_numbers: "",
      photo_url: null,
      twitter_url: null,
    },
    loading: false,
  };

  setModalOpen = (value) => {
    this.setState({ modalOpen: value });
  };

  handleChange = ({ currentTarget: input }) => {
    const { data } = this.state;
    data[input.name] = input.value;
    this.setState({ data });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitted");
    this.setState({ loading: true });
    if (parseInt(localStorage.getItem("credits"))===0){
      Notification("You have used up your free quota limit. Create an account to do more searches!", "error")
      this.setState({ loading: false });
      return ""
    }

    try {
      const response = await searchPeople(this.state.data);
      if (response.status===204){
        console.log(response)
        Notification("No Results Found. Try running a different query!", "error")
      }
      else{
        this.setState({ peopledata: response.data.data });
        localStorage.setItem("credits", 0);
        this.setModalOpen(true);
      }
    } catch (ex) {
      console.log(ex.response.data.status);
      Notification(ex.response.data.status, "error")
    }
    this.setState({ loading: false });
  };
  render() {
    const { modalOpen, loading, peopledata } = this.state;

    return (
      <>
        <div className="py-16 bg-gray-50 overflow-hidden lg:py-24">
          <div className="max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
            <div className="pb-12">
              <h3 className="pb-4 text-center text-2xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                Find People you want at free of cost.
              </h3>
              <h3 className="text-center text-base leading-8 tracking-tight text-gray-900 sm:text-base sm:leading-10">
                Search people you are looking for.
              </h3>
            </div>
            <div className="bg-white shadow-md md:p-4 lg:mx-48 rounded-2xl">
              <div className="px-4 py-6 bg-white">
                <p className="mb-4 text-sm text-center text-gray-400">
                  Enter a company website to start the search
                </p>
                <form onSubmit={this.handleSubmit}>
                  <div className="grid grid-cols-2 sm:gap-x-8 gap-x-4">
                    <TextInput
                      name="domain"
                      label="Domain"
                      onChange={this.handleChange}
                      placeholder="datachroma.com"
                      required
                    />
                    <TextInput
                      name="job_title"
                      label="Job Title"
                      onChange={this.handleChange}
                      placeholder="Founder"
                      required
                    />
                  </div>
                  {/* <TextInput label="Domain" /> */}
                  <div className="flex justify-center w-full pt-4">
                    <button
                      className="btn-lg w-full bg-indigo-600 hover:bg-indigo-500 text-white disabled:bg-indigo-300 disabled:cursor-not-allowed"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          <CircularProgress
                            className="mr-2"
                            style={{ width: 13, height: 13, color: "#fff" }}
                          />
                          Searching...
                        </>
                      ) : (
                        <>Find</>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ModalBasic
          id="feedback-modal"
          modalOpen={modalOpen}
          setModalOpen={this.setModalOpen}
          title={`${peopledata?.first_name} ${peopledata?.last_name} - ${peopledata?.headline}`}
        >
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-full sm:col-span-6 xl:col-span-12 bg-white shadow-lg rounded-sm border border-gray-200">
              <div className="flex flex-col h-full">
                <div className="flex-grow p-5">
                  <div className="flex justify-between items-start">
                    <header>
                      <div className="flex mb-2">
                        {/* <a
                              className="relative inline-flex items-start mr-5"
                              href="/team/team-tiles#0"
                            >
                              <div
                                className="absolute top-0 right-0 -mr-2 bg-white rounded-full shadow"
                                aria-hidden="true"
                              ></div>
                              <img
                                className="rounded-full"
                                src={peopledata?.photo_url}
                                width="64"
                                height="64"
                                alt={`${peopledata?.first_name} ${peopledata?.last_name}`}
                              ></img>
                            </a> */}
                        <div className="mt-1 pr-1">
                          <a
                            className="inline-flex text-gray-800 hover:text-gray-900"
                            href={peopledata?.linkedin_url}
                            rel="nofollow"
                          >
                            <h2 className="text-xl leading-snug justify-center font-semibold">
                              {`${peopledata?.first_name} ${peopledata?.last_name}`}
                            </h2>
                            <BadgeCheckIcon className="h-6 w-6 ml-2 text-green-500" />
                          </a>
                        </div>
                      </div>
                    </header>
                    <div className="relative inline-flex flex-shrink-0">
                      <button
                        className="text-gray-400 hover:text-gray-500 rounded-full false"
                        aria-haspopup="true"
                        aria-expanded="false"
                      ></button>
                    </div>
                  </div>
                  <div className="mt-2">
                    <div className="flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        data-name="Layer 1"
                        viewBox="0 0 24 24"
                        height="24"
                        width="24"
                      >
                        <path d="M17.5,8.999a5.41868,5.41868,0,0,0-2.56543.64453A.99918.99918,0,0,0,14,8.999H10a.99943.99943,0,0,0-1,1v12a.99942.99942,0,0,0,1,1h4a.99942.99942,0,0,0,1-1v-5.5a1,1,0,1,1,2,0v5.5a.99942.99942,0,0,0,1,1h4a.99942.99942,0,0,0,1-1v-7.5A5.50685,5.50685,0,0,0,17.5,8.999Zm3.5,12H19v-4.5a3,3,0,1,0-6,0v4.5H11v-10h2v.70313a1.00048,1.00048,0,0,0,1.78125.625A3.48258,3.48258,0,0,1,21,14.499Zm-14-12H3a.99943.99943,0,0,0-1,1v12a.99942.99942,0,0,0,1,1H7a.99942.99942,0,0,0,1-1v-12A.99943.99943,0,0,0,7,8.999Zm-1,12H4v-10H6ZM5.01465,1.542A3.23283,3.23283,0,1,0,4.958,7.999h.02832a3.23341,3.23341,0,1,0,.02832-6.457ZM4.98633,5.999H4.958A1.22193,1.22193,0,0,1,3.58887,4.77051c0-.7461.55957-1.22852,1.42578-1.22852A1.2335,1.2335,0,0,1,6.41113,4.77051C6.41113,5.5166,5.85156,5.999,4.98633,5.999Z" />
                      </svg>
                      <div className="text-sm ml-2 pt-1">
                        {peopledata?.email ? (
                          <>Email: {peopledata?.email}</>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="text-sm ml-2 pt-1">
                        {peopledata?.phone_numbers ? (
                          <>Phone Numbers: {peopledata?.phone_numbers}</>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-t border-gray-200">
                  <div className="flex divide-x divide-gray-200r">
                    <a
                      className="block flex-1 text-center text-sm text-indigo-500 hover:text-indigo-600 font-medium px-3 py-4"
                      href={`mailto:${peopledata?.email}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="flex items-center justify-center">
                        <ChatIcon className="h-7 w-7 mr-2" />
                        <span>Send Email</span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBasic>
      </>
    );
  }
}

export default Search;
